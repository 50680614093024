import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import Author from "./author"

const CardPost = ({
  title,
  content,
  img,
  category,
  to,
  full,
  date,
  author,
  className,
}) => {
  const linkClass = classNames({
    "group flex flex-wrap col-start-1 col-end-4 bg-white shadow rounded overflow-hidden transform transition duration-300 ease-out hover:-translate-y-2 hover:shadow-lg": true,
    [className]: className,
    "md:grid-cols-5": full,
    "md:flex-col md:col-start-auto md:col-end-auto": !full,
    "md:grid-cols-full md:col-start-auto md:col-end-auto": !full,
  })

  const titleClass = classNames({
    "font-display text-2xl text-dark transition group-hover:text-black": true,
    "md:text-4xl": full,
  })

  const excerptClass = classNames({
    "mb-0 text-gray-600": true,
    "text-lg": full,
  })

  const colLeftClass = classNames({
    "w-full": true,
    "lg:w-5/12": full,
  })

  const imgWrapperClass = classNames({
    "overflow-hidden rounded-t safari-overflow-fix aspect-w-16 aspect-h-9": true,
    "lg:h-full lg:rounded-r-none lg:rounded-l": full,
  })

  const innerClass = classNames({
    "flex flex-col p-6 lg:flex-1 w-full": true,
    "lg:p-8 ": full,
  })

  return (
    <Link to={to} className={linkClass}>
      <div className={colLeftClass}>
        <GatsbyImage image={img} alt={title} className={imgWrapperClass} />
      </div>

      <div className={innerClass}>
        <Author
          author={author}
          size="sm"
          date={date}
          className="mb-6 !text-black"
        />

        <div className="mb-2 font-display text-sm text-gray-600">
          {category}
        </div>

        <h2 className={titleClass}>{title}</h2>

        <div className={excerptClass}>{content}</div>

        <div className="mt-auto">
          <span className="inline-block mt-4 font-bold text-blue border-b-2 border-blue">
            Read more
          </span>
        </div>
      </div>
    </Link>
  )
}

CardPost.defaultProps = {
  full: false,
}

export default CardPost
