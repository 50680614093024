import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Grid } from "../components/ui/grid"
import CardPost from "../components/ui/card-post"
import BeatLoader from "react-spinners/BeatLoader"

const BlogArchiveContentfulTemplate = ({ data, location, perLoad }) => {
  const currentCategory = data.contentfulCategory
  const allPosts = data.allContentfulPost.edges

  const [posts, setPosts] = React.useState([...allPosts.slice(0, perLoad)])
  const [loadMore, setLoadMore] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(allPosts.length > perLoad)
  const loadRef = React.useRef()

  // Handle intersection with load more div
  const handleObserver = entities => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  // Initialize the intersection observer API
  React.useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options)

    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  React.useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = posts.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + perLoad)
        : []

      setPosts([...posts, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  // Check if there is more
  React.useEffect(() => {
    const isMore = posts.length < allPosts.length
    setHasMore(isMore)
  }, [posts]) //eslint-disable-line

  return (
    <Layout location={location}>
      <Seo
        title={currentCategory.name}
        description={currentCategory.description || currentCategory.name}
      />

      <Container className="mt-12 mb-5 max-w-6xl text-center md:text-left">
        <h1 className="md:mb-6">{currentCategory.name}</h1>

        <div className="mt-8 flex flex-wrap max-w-6xl md:mt-12">
          <Link
            to="/blog"
            className="font-bold text-blue border-b-2 border-blue">
            Go back to blog
          </Link>
        </div>
      </Container>

      <div className="py-12 bg-gray-100 lg:py-20">
        <Container className="max-w-6xl">
          <Grid md={2} lg={3}>
            {posts.map((item, index) => {
              return (
                <CardPost
                  key={item.node.id}
                  title={item.node.title}
                  category={item.node.category.name}
                  author={item.node.author}
                  content={item.node.description}
                  to={`/blog/${item.node.slug}`}
                  img={item.node.featuredImage.gatsbyImageData}
                  full={index === 0}
                />
              )
            })}
          </Grid>

          <div ref={loadRef} className="text-center pt-12">
            <BeatLoader color="black" loading={hasMore} size={20} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

BlogArchiveContentfulTemplate.defaultProps = {
  perLoad: 15,
}

export default BlogArchiveContentfulTemplate

export const pageQuery = graphql`
  query BlogArchiveContentfulBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      id
      name
      slug
      description
    }
    allContentfulPost(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          title
          slug
          description
          publishDate(formatString: "MMMM Do, YYYY")
          category {
            name
            slug
          }
          featuredImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          author {
            name
            photo {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`
