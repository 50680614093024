import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"

const Author = ({ author, size, date, className }) => {
  const wrapperClass = classNames({
    "text-sm": size === "sm",
    [className]: className,
  })

  const imgWrapperClass = classNames({
    "mr-3": true,
    "w-11 ": date,
    "w-10 ": !date,
  })

  return (
    <div className={wrapperClass}>
      <div className="flex flex-wrap items-center">
        <div className={imgWrapperClass}>
          <GatsbyImage
            image={author?.photo?.gatsbyImageData}
            alt={author?.name}
            className="aspect-w-1 aspect-h-1 rounded-full safari-overflow-fix"
          />
        </div>

        <div>
          <div>
            {!date && "by"} <span className="font-bold">{author?.name}</span>
          </div>

          {date && <time>{date}</time>}
        </div>
      </div>
    </div>
  )
}

Author.defaultProps = {
  size: "default",
  date: false,
}

export default Author
